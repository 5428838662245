<script setup>
import { getCurrentInstance, ref } from "vue";
import _ from "lodash";
import Cookies from "js-cookie";
import Service from "@/libs/service.js";
import { calcPasswordStrength, isEmail } from "@/utils/validate.js";
import router from "@/router/index.js";

const emits = defineEmits(["to-login"]);

const el = getCurrentInstance();

const form_ref = ref(null);

const signupForm = ref({
    username: "",
    password: "",
    confirmPassword: "",
    email: "",
    emailVerified: false,
    emailCaptcha: "",
    mobile: "",
});

function validateUsername(rule, value, callback) {
    if (!value)
        return callback(new Error(el.proxy.$t("x_username_empty_err")));
    else
        return callback();
}

function validatePassword(rule, value, callback) {
    if (!value) {
        return callback(new Error(el.proxy.$t("x_password_empty_err")));
    }
    else {
        const state = calcPasswordStrength(value);
        let errorMsg = "";
        if (state[0] !== "success") {
            errorMsg += el.proxy.$t("x_password_rule_1");
        }
        else if (state[1] !== "success") {
            errorMsg += errorMsg ? "，" : "";
            errorMsg += el.proxy.$t("x_password_rule_2");
        }
        else if (state[3] !== "success") {
            errorMsg += errorMsg ? "，" : "";
            errorMsg += el.proxy.$t("x_password_rule_3");
        }
        if (errorMsg)
            return callback(new Error(errorMsg));
        else
            return callback();
    }
}

function validatePassword2(rule, value, callback) {
    if (!value)
        return callback(new Error(el.proxy.$t("x_comfirm_password_empty_err")));
    else if (value !== signupForm.value.password)
        return callback(new Error(el.proxy.$t("x_password_err")));
    else
        return callback();
}

function validateEmail(rule, value, callback) {
    const email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/;
    // var email =
    //   /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

    if (!value)
        return callback(new Error(el.proxy.$t("x_empty_err")));
    else if (!email.test(value))
        callback(new Error(el.proxy.$t("x_email_invlidate")));
    else
        return callback();
}

function validateEmpty(rule, value, callback) {
    if (!value)
        return callback(new Error(el.proxy.$t("x_empty_err")));
    else
        return callback();
}

const rules = {
    username: [

        { validator: validateUsername, trigger: "none" },
    ],
    password: [

        { validator: validatePassword, trigger: "none" },
    ],
    confirmPassword: [

        { validator: validatePassword2, trigger: "none" },
    ],
    email: [

        { validator: validateEmail, trigger: "none" },
    ],
    emailCaptcha: [

        { validator: validateEmpty, trigger: "none" },
    ],
    mobile: [

        { validator: validateEmpty, trigger: "none" },
    ],
};

const sendVerifyCodeCD = ref(0);
let sendVerifyCodeCDTimer = null;

function checkEmail() {
    if (sendVerifyCodeCD.value > 0)
        return;
    const email = _.trim(signupForm.value.email);

    if (email !== "" && isEmail(email)) {
        Service.request(
            "get",
            `/sys/user/signup/check?type=user_email&value=${email}`,
            {},
            (dt) => {
                if (dt.data === 1)
                    sendVerifyCode();
                else
                    el.proxy.$Message.error(`${email} already registered`);
            },
            () => { },
        );
    }
    else {
        el.proxy.$Message.error(el.proxy.$t("x_email_invlidate"));
    }
}

function sendVerifyCode() {
    signupForm.value.emailVerified = false;

    const email = _.trim(signupForm.value.email);

    Service.request(
        "get",
        `/sys/user/signup/send?to=${email}`,
        {},
        (dt) => {
            if (dt.code === 0) {
                el.proxy.$Message.success(el.proxy.$t("x_send_verification_code_tip"));
                sendVerifyCodeCD.value = 60;
                sendVerifyCodeCDTimer = setInterval(() => {
                    if (sendVerifyCodeCD.value-- <= 0) {
                        clearInterval(sendVerifyCodeCDTimer);
                        sendVerifyCodeCDTimer = null;
                    }
                }, 1000);
            }
        },
        () => { },
    );
}

function verifyEmail(email, code) {
    return new Promise((resolve, reject) => {
        Service.request(
            "get",
            `/sys/user/signup/check?type=email_captcha&value=${email}::${code.toLocaleUpperCase()}`,
            {},
            (dt) => {
                if (dt.data === 1)
                    resolve();
                else
                    reject(new Error(`Verify code is not correct`));
            },
        );
    });
}

const isShowSignupSuccess = ref(true);

function signup() {
    form_ref.value.validate((valid) => {
        if (!valid)
            return;

        verifyEmail(signupForm.value.email, signupForm.value.emailCaptcha)
            .then(() => {
                const data = {
                    username: signupForm.value.username,
                    password: signupForm.value.password,
                    email: signupForm.value.email,
                    mobile: signupForm.value.mobile,
                };
                if (el.proxy.$route.query.inviteCode)
                    data.inviteCode = el.proxy.$route.query.inviteCode;
                if (el.proxy.$route.query.openId)
                    data.openId = el.proxy.$route.query.openId;
                Service.request(
                    "post",
                    "/sys/user/signup",
                    data,
                    (dt) => {
                        Cookies.set("access", dt.data.roleIds.toString());
                        Cookies.set("user", dt.data.username);
                        Cookies.set("status", dt.data.status);
                        Cookies.set("userId", dt.data.userId);
                        Cookies.set("companyId", dt.data.companyId);
                        if (el.proxy.$route.query.inviteCode) {
                            // 通过邀请链接注册成功后，直接跳转登录
                            router.push({
                                name: "login",
                                query: {
                                    type: 1,
                                },
                            });
                        }
                        else {
                            // 提示注册成功
                            // isShowSignupSuccess.value = true;
                            el.proxy.$router.push({
                                name: "signup-success",
                            });
                        }
                    },
                    (err) => {
                        if (err.msg === "The phone number has been registered, please log in directly.")
                            emits("to-login");
                    },
                );
            })
            .catch((err) => {
                el.proxy.$Message.error(err.message);
            });
    });
}
</script>

<template>
    <div>
        <div class="signup-header">
            <h1>
                {{ $t("x_sign_up_title") }}
            </h1>
        </div>
        <i-form ref="form_ref" :model="signupForm" :rules="rules" label-position="top">
            <FormItem prop="username" :label="$t('x_username')">
                <Input v-model="signupForm.username" size="large" :placeholder="$t('x_username')" />
            </FormItem>
            <FormItem prop="password" :label="$t('x_password')">
                <Input v-model="signupForm.password" size="large" type="password" :placeholder="$t('x_password')" />
            </FormItem>
            <FormItem prop="confirmPassword" :label="$t('x_confirm_password')" style="margin-top: 4px">
                <Input v-model="signupForm.confirmPassword" size="large" type="password" :placeholder="$t('x_confirm_password')" />
            </FormItem>
            <FormItem prop="email" :label="$t('x_email')">
                <Input v-model="signupForm.email" size="large" :placeholder="$t('x_email')" />
            </FormItem>
            <FormItem prop="emailCaptcha" :label="$t('x_verification_code')">
                <Input v-model="signupForm.emailCaptcha" size="large" class="email-captcha-input">
                    <template #suffix>
                        <span class="send-verification-code" @click="checkEmail">
                            {{ sendVerifyCodeCD <= 0 ? $t("x_send_verification_code") : sendVerifyCodeCD }}
                        </span>
                    </template>
                </Input>
            </formitem>
            <FormItem>
                <Button type="primary" long size="large" @click="signup">
                    {{ $t("x_signup") }}
                </Button>
            </FormItem>
        </i-form>
        <Row type="flex" justify="center" style="margin-top: 1.2rem">
            <p>
                {{ $t("x_already_member") }} <a @click.prevent="emits('to-login')">{{ $t("x_login") }}</a>
            </p>
        </Row>
    </div>
</template>

<style scoped lang="less">
.email-captcha-input :deep(.ivu-input-suffix) {
    padding-right: @gt-space-2;
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.email-captcha-input :deep(.ivu-input-with-suffix) {
    padding-right: 160px;
}

.send-verification-code {
    color: @gt-brand-6;
    cursor: pointer;
    user-select: none;

    &:hover {
        color: @gt-brand-5;
    }
    &:active {
        color: @gt-brand-6;
    }
}

.signup-header {
    margin-bottom: 20px;
}
</style>
