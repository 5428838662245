const util = {};

util.inOf = function (arr, targetArr) {
    let res = true;
    arr.forEach((item) => {
        if (!targetArr.includes(item))
            res = false;
    });
    return res;
};

util.oneOf = function (ele, targetArr) {
    return !!targetArr.includes(ele);
};

util.title = function (to) {
    console.log(to);

    if (to.meta) {
        if (to.meta.title && window.systemVue)
            window.document.title = `GT-${window.systemVue?.$t(to.meta?.title)}`;
    }

    else { window.document.title = "GT空间孪生平台"; }
};

util.getRouterObjByName = function (routers, name) {
    if (!name || !routers || !routers.length)
        return null;

    let routerObj = null;
    for (const item of routers) {
        if (item.name === name)
            return item;

        routerObj = util.getRouterObjByName(item.children, name);
        if (routerObj)
            return routerObj;
    }
    return null;
};

util.handleTitle = function (vm, item) {
    if (typeof item.title === "object")
        return vm.$t(item.title.i18n);
    else
        return item.title;
};

util.toDefaultPage = function (routers, name, route, next) {
    const len = routers.length;
    let i = 0;
    let notHandle = true;
    while (i < len) {
        if (
            routers[i].name === name
            && routers[i].children
            && routers[i].redirect === undefined
        ) {
            route.replace({
                name: routers[i].children[0].name,
            });
            notHandle = false;
            next();
            break;
        }
        i++;
    }
    if (notHandle)
        next();
};

// rc4 encryption/decryption
util.streamTranslator = function (token, content) {
    return new Promise((resolve) => {
        const s = [];
        let j = 0;
        let x;
        let res = "";
        for (let i = 0; i < 256; i++)
            s[i] = i;

        for (let i = 0; i < 256; i++) {
            j = (j + s[i] + token.charCodeAt(i % token.length)) % 256;
            x = s[i];
            s[i] = s[j];
            s[j] = x;
        }

        let i = 0;
        j = 0;
        let charCodeBuffer; // Uint8Array

        if (typeof content == "string") {
            // string -> bin
            const strLength = content.length;
            charCodeBuffer = new Uint8Array(strLength);

            for (let y = 0; y < strLength; y++) {
                i = (i + 1) % 256;
                j = (j + s[i]) % 256;
                x = s[i];
                s[i] = s[j];
                s[j] = x;
                charCodeBuffer[y] = content.charCodeAt(y) ^ s[(s[i] + s[j]) % 256];
            }

            res = new Blob([charCodeBuffer.buffer]);
            resolve(res);
        }
        else {
            // bin -> string
            // Instead suppose the bin is directly an ArrayBuffer
            charCodeBuffer = new Uint8Array(content);
            for (let y = 0; y < charCodeBuffer.length; y++) {
                i = (i + 1) % 256;
                j = (j + s[i]) % 256;
                x = s[i];
                s[i] = s[j];
                s[j] = x;
                res += String.fromCharCode(
                    charCodeBuffer[y] ^ s[(s[i] + s[j]) % 256],
                );
            }

            resolve(res);
        }
    });
};

export default util;
