import VueI18n from "vue-i18n";
import ViewUI from "view-design";
import zhCNVxe from "vxe-table/lib/locale/lang/zh-CN";
import enUSVxe from "vxe-table/lib/locale/lang/en-US";
import zhTCVxe from "vxe-table/lib/locale/lang/zh-TC";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import zhCNDayjs from "dayjs/locale/zh-cn"; // 导入简体中文语言包
import zhTCDayjs from "dayjs/locale/zh-tw"; // 导入繁体中文语言包
import enUSDayjs from "dayjs/locale/en"; // 导入英文语言包
import enLocale from "view-design/src/locale/lang/en-US";
import zhCNLocale from "view-design/src/locale/lang/zh-CN";
import zhTWLocale from "view-design/src/locale/lang/zh-TW";
import Vue from "vue";
import "./style.css";
import DisableDevtool from "disable-devtool";

import "@/assets/style/theme.less";
import _ from "lodash";
import uploader from "vue-simple-uploader";
import VXETable from "vxe-table";
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

import App from "./App.vue";
import store from "./store/index.js";
import router from "./router/index.js";
import "../public/assets/iconfont/iconfont.css";
import "../public/assets/iconfont/iconfont.js";
import "view-design/dist/styles/iview.css";
import Locales from "./locale";
import directives from "@/libs/directive";

import "vxe-table/lib/style.css";

/// 字体图标
import "@/assets/fontawesome/fontawesome.css";
import "@/assets/fontawesome/solid.less";
import "@/assets/fontawesome/light.less";
/// 字体图标

/// 全局lodash
window._ = _;
/// 全局lodash ///

Vue.use(VueI18n);
Vue.use(ViewUI);
Vue.use(uploader);
Vue.use(directives);
Vue.use(VXETable);
Vue.use(Viewer);

Vue.component("codemirror", codemirror);

Vue.locale = () => {};

Viewer.setDefaults({
    inline: false,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: "fullpath",
});

/// 国际化
const navLang = navigator.language;
const localLang = navLang === "zh-CN" || navLang === "en-US" ? navLang : false;
const lang = window.localStorage.getItem("language") || localLang || "zh-CN";

Vue.config.lang = lang;
window.localStorage.language = lang;

const locales = Locales;

const messages = {
    "en-US": Object.assign(locales["en-US"], enUSVxe, enLocale, enUSDayjs),
    "zh-CN": Object.assign(locales["zh-CN"], zhCNVxe, zhCNLocale, zhCNDayjs),
    "zh-TW": Object.assign(locales["zh-TW"], zhTCVxe, zhTWLocale, zhTCDayjs),
};

const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: "en-US",
    messages,
});
/// 国际化 ///

window.dayjs = dayjs;
dayjs.extend(relativeTime);
dayjs.locale(i18n.locale);

VXETable.setup({
    icon: {
        TABLE_SORT_ASC: "ivu-icon ivu-icon-md-arrow-dropup",
        TABLE_SORT_DESC: "ivu-icon ivu-icon-md-arrow-dropdown",
        TABLE_FILTER_NONE: "ivu-icon ivu-icon-ios-funnel",
        TABLE_FILTER_MATCH: "ivu-icon ivu-icon-ios-funnel",
        TABLE_CHECKBOX_CHECKED: "vxe-icon-checkbox-checked",
        TABLE_CHECKBOX_UNCHECKED: "vxe-icon-checkbox-unchecked",
        TABLE_CHECKBOX_INDETERMINATE: "vxe-icon-checkbox-indeterminate",
        TOOLBAR_TOOLS_CUSTOM: "icon iconfont icon-list",
    },
    i18n: (key, args) => i18n.t(key, args),
    translate(key, args) {
        // 只翻译 "x_" 开头的键值
        if (key && key.includes("x_"))
            return i18n.t(key, args);

        return key;
    },
});

// const switchMenu = sessionStorage.getItem("switchMenu");
// if (switchMenu)
//     store.commit("setSwitchMenu", true);

// const activeMenuName = sessionStorage.getItem("activeMenuName");
// if (activeMenuName)
//     store.commit("setActiveMenuName", activeMenuName);

/// 主题
// 默认亮色主题
document.body.setAttribute("gt-theme", "sdmslight");
/// 主题 ///

window.isSubDomainSupport = true;
window.isProduction = import.meta.env.PROD;

/// 禁用devtool
// DisableDevtool({
//     md5: DisableDevtool.md5("dev"),
//     disableMenu: false,
//     clearLog: true,
//     ignore: () => {
//         // 仅在开发环境下禁用devtool
//         return import.meta.env.DEV;
//     },
// });
/// 禁用devtool ///
Vue.prototype._i18n = i18n;
Vue.prototype.$bus = new Vue();
Vue.prototype.$addStorageEvent = function (type, key, data) {
    let newStorageEvent;
    // localStorage
    if (type === 1) {
        // 创建一个StorageEvent事件
        newStorageEvent = document.createEvent("StorageEvent");
        const storage = {
            setItem(k, val) {
                localStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageEvent.initStorageEvent("setItem", false, false, k, null, val, null, null);
                // 派发对象
                window.dispatchEvent(newStorageEvent);
            },
        };
        return storage.setItem(key, data);
    }
    else {
        // sessionStorage
        // 创建一个StorageEvent事件
        newStorageEvent = document.createEvent("StorageEvent");
        const storage = {
            setItem(k, val) {
                sessionStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageEvent.initStorageEvent("setItem", false, false, k, null, val, null, null);
                // 派发对象
                window.dispatchEvent(newStorageEvent);
            },
        };
        return storage.setItem(key, data);
    }
};

const app = new Vue({
    i18n,
    store,
    router,
    render: createElement => createElement(App),
});
window.systemVue = app;
app.$mount("#app");
