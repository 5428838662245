import Vue from "vue";
import Vuex from "vuex";
import Cookies from "js-cookie";
import user from "@/store/modules/user.js";
import menu from "@/store/modules/menu.js";
import company from "@/store/modules/company.js";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        routerName: "",
        deviceType: "PC", // PC, mobile, iPad
        isCn: false,
    },
    mutations: {
        setRouterName(state, routerName) {
            state.routerName = routerName;
        },
        setDeviceType(state, deviceType) {
            Cookies.set("deviceType", deviceType);
            state.deviceType = deviceType;
        },
        setIsCn(state, isCn) {
            state.isCn = isCn;
        },
        autoSetIsCn(state) {
            const hostname = window.location.hostname;
            if (/(\.cn$)|(\.cn\.)/.test(hostname) || hostname.includes("localhost"))
                state.isCn = true;
            else
                state.isCn = false;
        },
    },
    actions: {
        autoSetDeviceType({ commit }) {
            const device = navigator.userAgent.toLowerCase();
            if (/iPad/.test(navigator.userAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1))
                commit("setDeviceType", "iPad");
            else if (device.includes("android") || device.includes("ios") || device.includes("iphone") || device.includes("mobile"))
                commit("setDeviceType", "mobile");
            else
                commit("setDeviceType", "PC");
        },
    },
    modules: {
        user,
        menu,
        company,
    },
});

export default store;
