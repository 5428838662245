const projectRoutes = [
    {
        path: "/projectOverview",
        name: "project-overview",
        meta: {
            title: "x_projects_overview",
        },
        component: () => import("@/pages/projects/projectOverview.vue"),
    },
    {
        path: "/project",
        name: "project-list",
        meta: {
            title: "x_design_scheme_project_list",
        },
        component: () => import("@/pages/projects/index.vue"),
    },
    {
        path: "/projectShared",
        name: "project-shared",
        meta: {
            title: "x_shared",
        },
        component: () => import("@/pages/projects/shared/index.vue"),
    },
];

export default projectRoutes;
