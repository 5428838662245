<script setup>
import { getCurrentInstance, ref, watch } from "vue";
import ForgetPassword from "@/pages/auth/login/ForgetPwdForm.vue";
import LoginForm from "@/pages/auth/login/LoginForm.vue";
import SignupForm from "@/pages/auth/login/SignupForm.vue";

const el = getCurrentInstance();

const currentForm = ref("login");

watch(() => el.proxy.$route, () => {
    console.log(el.proxy.$route.query);
    const query = el.proxy.$route.query;
    if (query?.type) {
        if (query.type.startsWith("1"))
            currentForm.value = "login";
        else if (query.type.startsWith("2"))
            currentForm.value = "signup";
        else
            currentForm.value = "login";
    }
}, { immediate: true });
</script>

<template>
    <div>
        <LoginForm v-show="currentForm === 'login'" @to-signup="currentForm = 'signup'" @to-forget-pwd="currentForm = 'forget-pwd'" />
        <SignupForm v-show="currentForm === 'signup'" @to-login="currentForm = 'login'" />
        <ForgetPassword v-show="currentForm === 'forget-pwd'" @to-login="currentForm = 'login'" />
    </div>
</template>

<style scoped lang="less">
.auth-card-header {
    margin-bottom: 20px;
}
</style>
