const storageRoutes = [
    {
        path: "/dataStorage",
        name: "data-storage",
        meta: {
            title: "x_menu_storage",
        },
        component: () => import("@/pages/data/storage.vue"),
    },
    {
        path: "/dataResource",
        name: "data-resource",
        meta: {
            title: "x_menu_resources",
        },
        component: () => import("@/pages/data/resource.vue"),
    },
    {
        path: "/dataShared",
        name: "data-shared",
        meta: {
            title: "x_menu_share",
        },
        component: () => import("@/pages/data/shared.vue"),
    },
];

export default storageRoutes;
