import Cookies from "js-cookie";
import Vue from "vue";
import { isLogin } from "@/utils/is.js";
import Service from "@/libs/service.js";

const user = {
    namespaced: true,
    state: {
        isLogin: isLogin(),
        roleAccess: JSON.parse(localStorage.getItem("roleAccess") || "{}"),
        menuAccess: JSON.parse(localStorage.getItem("menuAccess") || "{}"),
        userInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null,
        theme: localStorage.theme || "sdmslight",
        language: localStorage.language || "zh-CN",
        menu: [],
    },
    getters: {},
    mutations: {
        login(state, token) {
            Cookies.set("sdms_token", token);
            state.isLogin = true;
        },
        logout(state) {
            state.isLogin = false;
            Cookies.remove("user");
            Cookies.remove("access");
            Cookies.remove("companyId");
            Cookies.remove("userId");
            Cookies.remove("sdms_token");
            Cookies.remove("workspace_token");
            Cookies.remove("subDomain");
            const lang = localStorage.language;
            const theme = localStorage.theme;
            localStorage.clear();
            localStorage.language = lang;
            localStorage.theme = theme;
            localStorage.menuAccess = [];
            localStorage.roleAccess = [];
        },
        setRoleAccess(state, roleAccess) {
            localStorage.roleAccess = JSON.stringify(roleAccess);
            state.roleAccess = roleAccess;
        },
        setMenuAccess(state, menuAccess) {
            localStorage.menuAccess = JSON.stringify(menuAccess);
            state.menuAccess = menuAccess;
        },
        setUserInfo(state, userInfo) {
            const roleIds = userInfo.roleIds || [];
            Cookies.set("access", roleIds.toString());
            Cookies.set("userId", userInfo.userId);
            Cookies.set("user", userInfo.username);
            userInfo.companyId && Cookies.set("companyId", userInfo.companyId);
            Cookies.set("status", userInfo.status);
            localStorage.userInfo = JSON.stringify(userInfo);

            state.userInfo = userInfo;
        },
        setTheme(state, theme) {
            document.body.setAttribute("gt-theme", theme);
            localStorage.theme = theme;
            state.theme = theme;
            if (state.userInfo) {
                let settingStr = state.userInfo?.settings || "{}";
                if (typeof settingStr !== "string")
                    settingStr = JSON.stringify(settingStr);
                const setting = JSON.parse(settingStr);
                // while (typeof setting === "string") {
                //     debugger;
                //     setting = JSON.parse(settingStr);
                // }

                setting.theme = theme;
                state.userInfo.settings = JSON.stringify(setting);
            }
        },
        setLanguage(state, language) {
            if (!language || language === "undefined")
                return;

            Vue.config.lang = language;
            localStorage.language = language;
            state.language = language;
            if (state.userInfo) {
                let settingStr = state.userInfo?.settings || "{}";
                if (typeof settingStr !== "string")
                    settingStr = JSON.stringify(settingStr);

                const setting = JSON.parse(settingStr);
                // while (typeof setting === "string") {
                //     debugger;
                //     setting = JSON.parse(settingStr);
                // }

                setting.language = language;

                state.userInfo.settings = JSON.stringify(setting);
            }
        },

    },
    actions: {
        login({ commit }, loginData) {
            return new Promise((resolve, reject) => {
                Service.request(
                    "post",
                    "/sys/login",
                    loginData,
                    (dt) => {
                        if (dt.code !== 0)
                            return reject(dt);

                        commit("login", dt.token);
                        commit("setUserInfo", dt.data);
                        const settings = JSON.parse(dt.data.settings || "{}");
                        commit("setTheme", settings?.theme || "sdmslight");
                        commit("setLanguage", settings?.language || "en-US");
                        resolve(dt);
                    },
                    (err) => {
                        reject(err);
                    },
                );
            });
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                Service.request(
                    "post",
                    "/sys/logout",
                    "",
                    () => {
                        commit("logout");
                        resolve();
                    },
                );
            });
        },
        register({ commit }) {
            commit("login");
        },
        queryRoleAccess({ commit }, roleIds) {
            const roleAccessList = {};
            return new Promise((resolve, reject) => {
                Service.request(
                    "get",
                    "/charactar/page?pageNo=1&pageSize=9999",
                    "",
                    (data) => {
                        const roleData = data.data.list;
                        if (roleData.length > 0) {
                            roleData.forEach((el) => {
                                roleAccessList[el.roleCode] = !!roleIds.includes(el.id);
                            });
                        }
                        commit("setRoleAccess", roleAccessList);
                        resolve(data);
                    },
                    (err) => {
                        reject(err);
                    },
                );
            });
        },
        queryMenuAccess({ commit }, menuIds) {
            const menuAccessList = {};
            return new Promise((resolve, reject) => {
                Service.request(
                    "get",
                    "/menu/selectByMenu",
                    "",
                    (data1) => {
                        const menuData = data1.data;
                        if (menuData.length) {
                            menuData.forEach((el) => {
                                menuAccessList[el.permission] = !!menuIds.includes(el.menuId);
                            });
                        }
                        commit("setMenuAccess", menuAccessList);
                        resolve(data1);
                    },
                    (err) => {
                        reject(err);
                    },
                );
            });
        },
        queryUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                Service.request(
                    "get",
                    "/sys/user/info",
                    "",
                    (dt) => {
                        // Here to determine whether it's guest user and it has only one project so that we directly bring the guest into his project
                        const settings = JSON.parse(dt.data.settings || "{}");
                        // while (typeof settings === "string")
                        //     settings = JSON.parse(settings);
                        // dt.data.settings = JSON.stringify(settings);
                        commit("setUserInfo", dt.data);
                        commit("setTheme", settings?.theme || "sdmslight");
                        commit("setLanguage", settings?.language || "en-US");
                        resolve(dt);
                    },
                    (err) => {
                        reject(err);
                    },
                );
            });
        },
        saveUserInfo({ state }) {
            console.log(state.userInfo);
            const data = {
                userId: state.userInfo.userId,
                companyId: Number(Cookies.get("companyId")),
                roleId: Number(Cookies.get("access")),
                status: Number(Cookies.get("status")),
                settings: state.userInfo.settings,
                username: state.userInfo.username,
                mobile: state.userInfo.mobile,
            };
            Service.request(
                "post",
                "/sys/user/update",
                data,
                (dt) => {
                    console.log(dt);
                },
                (err) => {
                    console.error(err);
                },
            );
        },
        changeTheme({ commit, dispatch }, theme) {
            commit("setTheme", theme);
            dispatch("saveUserInfo");
        },
        changeLanguage({ commit, dispatch }, language) {
            commit("setLanguage", language);
            dispatch("saveUserInfo");
        },
    },
};

export default user;
