const menu = {
    state: {
        projectMenuList: [
            {
                icon: "icon iconfont icon-xiangmugailan",
                title: "x_projects_overview",
                path: "/projectOverview",
                name: "project-overview",
                permission: "sys_project_overview",
                children: null,
            },
            {
                icon: "icon iconfont icon-xiangmuliebiao",
                title: "x_design_scheme_project_list",
                path: "/project",
                name: "project-list",
                permission: "sys_project_management_view",
                children: null,
            },
            {
                icon: "icon iconfont icon-wodefenxiang",
                title: "x_shared",
                path: "/projectShared",
                name: "project-shared",
                permission: "sys_project_share",
                children: null,
            },
        ],
        dataMenuList: [
            {
                icon: "icon iconfont icon-cunchukongjian",
                title: "x_menu_storage",
                path: "/dataStorage",
                name: "data-storage",
                permission: "sys_storage",
                children: null,
            },
            {
                icon: "icon iconfont icon-ziyuanguanli",
                title: "x_menu_resources",
                path: "/dataResource",
                name: "data-resource",
                permission: "sys_resources",
                children: null,
            },
            {
                icon: "icon iconfont icon-wodefenxiang",
                title: "x_shared",
                path: "/dataShared",
                name: "data-shared",
                permission: "sys_resources_share",
                children: null,
            },
        ],
        assetMenuList: [
            {
                icon: "icon iconfont icon-xiangmugailan",
                title: "x_overview",
                path: "/asset?url=iot_overview&tp=generic",
                permission: "sys_asset_management",
                name: "iot_overview",
                children: null,
            },
            {
                icon: "icon iconfont icon-chanpin",
                title: "x_product",
                path: "/asset?url=product_list&tp=generic",
                permission: "sys_asset_management",
                name: "product_list",
                children: null,
            },
            {
                icon: "icon iconfont icon-shebei",
                title: "x_device",
                path: "/asset?url=iot_manage&tp=generic",
                permission: "sys_asset_management",
                name: "iot_manage",
                children: null,
            },
            {
                icon: "icon iconfont icon-jinggao1",
                title: "x_alert",
                path: "/asset?url=iot_alert_list&tp=generic",
                permission: "sys_asset_management",
                name: "iot_alert_list",
                children: null,
            },
            {
                icon: "icon iconfont icon-ditumoshi",
                title: "x_asset_mapview",
                path: "/asset?url=iot_map_view&tp=generic",
                permission: "sys_asset_management",
                name: "iot_map_view",
                children: null,
            },
        ],
        accountMenuList: [
            {
                icon: "icon iconfont icon-yonghuguanli",
                title: "x_user_management",
                path: "/account/usersList",
                permission: "sys_users_management",
                name: "account-userList",
                children: null,
            },
            {
                icon: "icon iconfont icon-caidanguanli",
                title: "x_menu_management",
                path: "/account/menuList",
                name: "account-menuList",
                permission: "sys_menu_management",
                children: null,
            },
            {
                icon: "icon iconfont icon-jiaoseguanli",
                title: "x_role_management",
                path: "/account/roleList",
                permission: "sys_role_management",
                name: "account-roleList",
                children: null,
            },
            {
                icon: "icon iconfont icon-bumenguanli",
                title: "x_department_management",
                path: "/account/department",
                name: "account-department",
                permission: "sys_dept_management",
                children: null,
            },
            {
                icon: "icon iconfont icon-wodexinxi",
                title: "x_menu_my_profile",
                path: "/account/profile",
                permission: "sys_personal_account",
                name: "account-profile",
                children: null,
            },
            {
                icon: "icon iconfont icon-gongsixinxi",
                title: "x_menu_company_profile",
                path: "/account/companyProfile",
                permission: "sys_company_management",
                name: "account-companyProfile",
                children: null,
            },
            {
                icon: "icon iconfont icon-layer-two",
                title: "x_base_layer_setting",
                path: "/account/companyLayer",
                permission: "sys_setting_management",
                name: "account-companyLayer",
                children: null,
            },
            {
                icon: "icon iconfont icon-wodexiaoxi",
                title: "x_menu_my_massage",
                path: "/account/noticesList",
                name: "account-noticesList",
                permission: "sys_notice_management",
                children: null,
            },
            {
                icon: "icon iconfont icon-rizhiguanli",
                title: "x_log_management",
                path: "/account/log",
                name: "account-log",
                permission: "sys_account_management_view",
                children: null,
            },
            {
                icon: "icon iconfont icon-outline",
                title: "x_template_management",
                path: "/account/dashboardTemplate",
                name: "account-dashboard-template",
                permission: "sys_account_management_view",
                children: null,
            },
            // {
            //     icon: "icon iconfont icon-outline",
            //     title: "x_ai_model_mgn",
            //     path: "/account/aiModel",
            //     name: "account-ai-model",
            //     children: null,
            // },
        ],
        projectCommonMenuList: [
            {
                icon: "icon iconfont icon-changjing",
                title: "x_scene",
                path: "/project/scene",
                name: "project-scene",
                permission: "sys_view_scene",
                children: null,
            },
            {
                icon: "icon iconfont icon-yibiaopan",
                title: "x_dashboard",
                path: "/project/dashboard",
                permission: "sys_view_dashboard",
                name: "project-dashboard",
                children: null,
            },
            {
                icon: "icon iconfont icon-ziyuanguanli",
                title: "x_overview_resourve",
                path: "/project/resource",
                permission: "sys_view_resource",
                name: "project-resource",
                children: null,
            },
            {
                icon: "icon iconfont icon-chengyuan",
                title: "x_members",
                path: "/project/members",
                permission: "sys_view_member",
                name: "project-members",
                children: null,
            },
            {
                icon: "icon iconfont icon-tongyong",
                title: "x_general",
                path: "/project/general",
                permission: "sys_project_management_view",
                name: "project-general",
                children: null,
            },
        ],
        projectSmartConstructionMenuList: [
            {
                icon: "icon iconfont icon-changjing",
                title: "x_scene",
                path: "/project/scene",
                permission: "sys_view_scene",
                name: "project-scene",
                children: null,
            },
            {
                icon: "icon iconfont icon-yibiaopan",
                title: "x_dashboard",
                path: "/project/dashboard",
                permission: "sys_view_dashboard",
                name: "project-dashboard",
                children: null,
            },
            {
                icon: "icon iconfont icon-ziyuanguanli",
                title: "x_overview_resourve",
                path: "/project/resource",
                permission: "sys_view_resource",
                name: "project-resource",
                children: null,
            },
            {
                icon: "icon iconfont icon-xiangmugailan",
                title: "x_overview",
                path: "/project/asset?url=iot_overview&tp=generic",
                permission: "sys_project_view_iot_overview",
                name: "iot_overview",
                children: null,
            },
            {
                icon: "icon iconfont icon-chanpin",
                title: "x_product",
                path: "/project/asset?url=product_list&tp=generic",
                permission: "sys_project_management_view",
                name: "product_list",
                children: null,
            },
            {
                icon: "icon iconfont icon-shebei",
                title: "x_device",
                path: "/project/asset?url=iot_manage&tp=generic",
                permission: "sys_project_management_view",
                name: "iot_manage",
                children: null,
            },
            {
                icon: "icon iconfont icon-jinggao1",
                title: "x_alert",
                path: "/project/asset?url=iot_alert_list&tp=generic",
                permission: "sys_project_management_view",
                name: "iot_alert_list",
                children: null,
            },
            {
                icon: "icon iconfont icon-ditumoshi",
                title: "x_asset_mapview",
                path: "/project/asset?url=iot_map_view&tp=generic",
                permission: "sys_project_management_view",
                name: "iot_map_view",
                children: null,
            },
            {
                icon: "icon iconfont icon-yujingzhongxin",
                title: "x_alert_center",
                path: "/project/asset?url=alert_center&tp=generic",
                permission: "sys_project_management_view",
                name: "alert_center",
                children: null,
            },
            {
                icon: "icon iconfont icon-chengyuan",
                title: "x_members",
                path: "/project/members",
                permission: "sys_view_member",
                name: "project-members",
                children: null,
            },
            {
                icon: "icon iconfont icon-tongyong",
                title: "x_general",
                path: "/project/general",
                permission: "sys_project_management_view",
                name: "project-general",
                children: null,
            },
        ],
        projectAIMenuList: [
            {
                icon: "icon iconfont icon-changjing",
                title: "x_scene",
                path: "/project/scene",
                permission: "sys_view_scene",
                name: "project-scene",
                children: null,
            },
            {
                icon: "icon iconfont icon-tongyong",
                title: "x_pms",
                path: "/project/albums",
                name: "project-ai-albums",
                children: null,
            },
            // {
            //     icon: "icon iconfont icon-tongyong",
            //     title: "x_pms",
            //     path: "/project/pms",
            //     name: "project-ai-pms",
            //     children: null,
            // },
            {
                icon: "icon iconfont icon-tongyong",
                title: "AI_APP_TASK",
                path: "/project/tasks",
                name: "project-tasks",
                children: null,
            },
            {
                icon: "icon iconfont icon-tongyong",
                title: "AI_APP_AI_REPORT",
                path: "/project/reports",
                name: "project-reports",
                children: null,
            },
            // {
            //     icon: "icon iconfont icon-tongyong",
            //     title: "AI_APP_AI_RESULT",
            //     path: "/project/results",
            //     name: "project-results",
            //     children: null,
            // },
            {
                icon: "icon iconfont icon-tongyong",
                title: "x_odm_task",
                path: "/project/odmtasks",
                name: "project-odmtasks",
                children: null,
            },
            {
                icon: "icon iconfont icon-tongyong",
                title: "x_splat_task",
                path: "/project/splattasks",
                name: "project-splattasks",
                children: null,
            },
            {
                icon: "icon iconfont icon-chengyuan",
                title: "x_members",
                path: "/project/members",
                name: "project-members",
                children: null,
            },
            {
                icon: "icon iconfont icon-tongyong",
                title: "x_general",
                path: "/project/general",
                name: "project-general",
                children: null,
            },
        ],
        tabsList: [{
            icon: "icon iconfont icon-xiangmugailan",
            title: "x_projects_overview",
            path: "/projectOverview",
            name: "project-overview",
            children: null,
        }],
        switchMenu: false,
        activeMenuName: "/projectOverview",
        projectQuery: {},
    },
    mutations: {
        setTabsList(state, value) {
            state.tabsList = value;
        },
        addTabsList(state, value) {
            if (state.tabsList.findIndex(item => item.name === value.name) === -1)
                state.tabsList.push(value);
        },
        delTabsList(state, value) {
            state.tabsList.forEach((element, index) => {
                if (element.title === value)
                    state.tabsList.splice(index, 1);
            });
        },
        setSwitchMenu(state, value) {
            state.switchMenu = value;
            if (value)
                sessionStorage.setItem("switchMenu", value);
            else
                sessionStorage.removeItem("switchMenu");
        },
        setActiveMenuName(state, value) {
            state.activeMenuName = value;
            if (value)
                sessionStorage.setItem("activeMenuName", value);
            else
                sessionStorage.removeItem("activeMenuName");
        },
        setProjectQuery(state, value) {
            state.projectQuery = value;
        },
    },
    getters: {
        getTabsList(state) {
            return state.tabsList;
        },
        getProjectMenuList(state) {
            return state.projectMenuList;
        },
        getProjectAIMenuList(state) {
            return state.projectAIMenuList;
        },
        getProjectCommonMenuList(state) {
            return state.projectCommonMenuList;
        },
        getProjectSmartConstructionMenuList(state) {
            return state.projectSmartConstructionMenuList;
        },
        getDataMenuList(state) {
            return state.dataMenuList;
        },
        getAssetMenuList(state) {
            return state.assetMenuList;
        },
        getAccountMenuList(state) {
            return state.accountMenuList;
        },
        getAllMenu(state) {
            const menuArr = state.projectMenuList.concat(state.dataMenuList, state.assetMenuList, state.accountMenuList);
            return menuArr;
        },
        getAllTemplateMenu(state) {
            const menuArr = state.projectMenuList.concat(state.projectAIMenuList, state.projectCommonMenuList, state.projectSmartConstructionMenuList);
            return menuArr;
        },
        getSwitchMenu(state) {
            return state.switchMenu;
        },
        getActiveMenuName(state) {
            return state.activeMenuName;
        },
        getProjectQuery(state) {
            return state.projectQuery;
        },
    },
};

export default menu;
