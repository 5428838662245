import { getCurrentInstance } from "vue";

export default function useFormValidator() {
    const el = getCurrentInstance();

    function validateEmail(rule, value, callback) {
        const email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/;
        if (!value)
            return callback(new Error(el.proxy.$t("x_empty_err")));
        else if (!email.test(value))
            callback(new Error(el.proxy.$t("x_email_invlidate")));
        else
            return callback();
    }

    return {
        validateEmail,
    };
}
