import { ref } from "vue";
import Cookies from "js-cookie";
import store from "@/store/index.js";
import router from "@/router/index.js";

export default function useAuth() {
    // const userSocketUrl = ref("");
    // const { ws: userSocket, open, close } = useWebSocket(
    //     userSocketUrl.value,
    //     {
    //         immediate: true,
    //     },
    // );

    const loading = ref(false);

    const loginData = ref({
        captcha: "",
        username: "",
        password: "",
        uuid: "",
    });

    function login(options) {
        loading.value = true;
        const data = {
            username: loginData.value.username,
            password: loginData.value.password,
            captcha: loginData.value.captcha,
            uuid: loginData.value.uuid,
        };

        options?.openId && (data.openId = options.openId);

        return store.dispatch("user/login", data)
            .then((dt) => {
                const userInfo = dt.data;

                if (userInfo.companyId === null) {
                    router.push({
                        name: "hub",
                    });
                    return;
                }

                const roleIds = userInfo.roleIds;
                const menuIds = userInfo.menuIds;

                return {
                    roleIds,
                    menuIds,
                };
            }).catch((dt) => {
                if (dt.code === 10000112) {
                    router.push({
                        name: "signup-success",
                    });
                }
            }).then(({ roleIds, menuIds }) => {
                return Promise.all([
                    store.dispatch("user/queryRoleAccess", roleIds),
                    store.dispatch("user/queryMenuAccess", menuIds),
                ]).then(() => {
                    if (localStorage.redirectUrl) {
                        let query = {};
                        if (localStorage.redirectObj)
                            query = JSON.parse(localStorage.redirectObj);

                        router.push({
                            name: localStorage.redirectUrl,
                            query,
                        });

                        localStorage.removeItem("redirectUrl");
                        localStorage.removeItem("redirectObj");
                        return;
                    }
                    if (localStorage.share_code && localStorage.share_p) {
                        Cookies.set("access", store.state.user.userInfo.roleIds.toString());
                        Cookies.set("user", store.state.user.userInfo.username);
                        Cookies.set("status", store.state.user.userInfo.status);
                        Cookies.set("userId", store.state.user.userInfo.userId);
                        Cookies.set("companyId", store.state.user.userInfo.companyId);

                        const shareHost
                        = window.baseHost === ""
                            ? `${window.location.protocol}//${window.location.host}`
                            : window.baseHost;

                        const link = `${shareHost}${window.baseAPI}` + `/index/ws/${localStorage.share_code}?p=${localStorage.share_p}&userId=${store.state.user.userInfo.userId}`;
                        window.open(link, "_self");

                        localStorage.removeItem("share_code");
                        localStorage.removeItem("share_p");
                    }
                    else {
                        if (window.root_platform) {
                            router.push({
                                name: "hub",
                            });
                        }
                        else {
                            Cookies.set("access", store.state.user.userInfo.roleIds.toString());
                            Cookies.set("user", store.state.user.userInfo.username);
                            Cookies.set("status", store.state.user.userInfo.status);
                            Cookies.set("userId", store.state.user.userInfo.userId);
                            Cookies.set("companyId", store.state.user.userInfo.companyId);

                            Promise.resolve();
                        }
                    }
                });
            }).then(() => {
                store.dispatch("user/queryUserInfo").then(() => {
                    Promise.resolve();
                });
            }).then(() => {
                if (store.state.user.roleAccess.GUEST) {
                    router.push({
                        name: "hub",
                    });
                }
                else {
                    router.push({
                        name: "project-list",
                    });
                }
            }).then(() => {

            }).finally(() => {
                loading.value = false;
            });
    }

    function logout() {
        return store.dispatch("user/logout").then(() => {
            router.push({
                name: "login",
            });
            console.log(store.getters["user/userSocket"]);
        });
    }

    return {
        loading,
        loginData,
        login,
        logout,
    };
}
