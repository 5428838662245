<script>
import { computed, ref, watchEffect } from "vue";
import { useElementSize } from "@vueuse/core";
import AppContent from "@/views/appContent/AppContent.vue";
import store from "@/store/index.js";

export default {
    components: { AppContent },
    setup() {
        const leftMenu = ref(null);
        const { width } = useElementSize(leftMenu);

        const showMenuTitle = ref(true);

        const menuAccess = computed(() => {
            return store.state.user.menuAccess;
        });

        watchEffect(() => {
            showMenuTitle.value = width.value >= 1000;
        });

        return {
            leftMenu,
            showMenuTitle,
            menuAccess,
        };
    },
    data() {
        return {
            menuList: [],
            routerName: "",
            tabsActive: "",
            // showMenuTitle: true,
            projectDetailsMenuList: [],
        };
    },
    computed: {
        // tabsList() {
        //     const tabsList = this.$store.getters.getTabsList;
        //     return tabsList;
        // },
        switchMenu() {
            return this.$store.getters.getSwitchMenu;
        },
        activeMenuName() {
            return this.$store.getters.getActiveMenuName;
        },
    },
    watch: {
        $route(to) {
            this.routerName = to.name;
        },
    },
    mounted() {
        // this.menuList = this.$store.getters.getProjectMenuList;
        this.projectDetailsMenuList = this.$store.getters.getProjectDetailsMenuList;
        this.routerName = this.$route.name;
        const preifx = this.$route.name.split("-")[0];
        switch (preifx) {
            case "project":
                this.menuList = this.$store.getters.getProjectMenuList;
                break;
            case "data":
                this.menuList = this.$store.getters.getDataMenuList;
                break;
            case "asset":
                this.menuList = this.$store.getters.getAssetMenuList;
                break;
            case "account":
                this.menuList = this.$store.getters.getAccountMenuList;
                break;
            default:
                this.menuList = this.$store.getters.getProjectMenuList;
                break;
        }
        // this.activeMenuName = this.$route.path;
        this.$store.commit("setActiveMenuName", this.$route.path);
    },
    methods: {
        getRefreshMenu(type) {
            const projectMenuList = this.$store.getters.getProjectMenuList;
            const dataMenuList = this.$store.getters.getDataMenuList;
            const assetMenuList = this.$store.getters.getAssetMenuList;
            const accountMenuList = this.$store.getters.getAccountMenuList;
            if (type === "project") {
                this.menuList = projectMenuList;
                this.$nextTick(() => {
                    this.$store.commit("setActiveMenuName", projectMenuList[1].path);
                    this.toPath(projectMenuList[1].path);
                    this.tabsActive = projectMenuList[1].title;
                });
                this.$store.commit("addTabsList", projectMenuList[1]);
            }
            else if (type === "data") {
                this.menuList = dataMenuList;
                this.$nextTick(() => {
                    this.$store.commit("setActiveMenuName", dataMenuList[0].path);
                    this.toPath(dataMenuList[0].path);
                    this.tabsActive = dataMenuList[0].title;
                });
                this.$store.commit("addTabsList", dataMenuList[0]);
            }
            else if (type === "asset") {
                this.menuList = assetMenuList;
                this.$nextTick(() => {
                    this.$store.commit("setActiveMenuName", assetMenuList[0].path);
                    this.toPath(assetMenuList[0].path);
                    this.tabsActive = assetMenuList[0].title;
                });
                this.$store.commit("addTabsList", assetMenuList[0]);
            }
            else if (type === "account") {
                this.menuList = accountMenuList;
                this.$nextTick(() => {
                    this.$store.commit("setActiveMenuName", "/account/profile");
                    this.toPath("/account/profile");
                    const subMenu = accountMenuList.find(item => item.path === "/account/profile");
                    this.tabsActive = subMenu.title;
                });
                this.$store.commit("addTabsList", accountMenuList[0]);
            }

            this.menuList = this.menuList.filter((item) => {
                return this.menuAccess[item.permission];
            });
        },
        toPath(item) {
            this.$router.push({
                path: item,
            });
        },
        toChangeMenuName(val) {
            const allMenuList = this.$store.getters.getAllMenu;
            let currentMenu = {};
            if (JSON.stringify(allMenuList).includes(val)) {
                allMenuList.map((value) => {
                    if (val === value.path)
                        currentMenu = value;
                    return currentMenu;
                });
            }
            this.$store.commit("addTabsList", currentMenu);
            this.tabsActive = currentMenu.title;
            this.$router.push({
                path: currentMenu.path,
            });
        },
        toChangeMenuTabs(val) {
            const allMenuList = this.$store.getters.getAllMenu;
            let currentTab = {};
            if (JSON.stringify(allMenuList).includes(val)) {
                allMenuList.map((value) => {
                    if (val === value.title)
                        currentTab = value;
                    return currentTab;
                });
            }
            this.tabsActive = currentTab.title;
            this.$router.push({
                path: currentTab.path,
            });
        },
        handleTabRemove(name) {
            this[`tab${name}`] = false;
            this.$store.commit("delTabsList", name);
        },
    },
};
</script>

<template>
    <div id="leftMenu" ref="leftMenu">
        <div :class="showMenuTitle ? 'left-menu' : 'left-menu-hide'">
            <div style="flex-grow: 1" class="menu-content">
                <Menu :active-name="activeMenuName" @on-select="toChangeMenuName">
                    <MenuItem v-for="(item, index) in menuList" :key="index" :name="item.path">
                        <template v-if="showMenuTitle">
                            <i :class="item.icon" />
                            <span>{{ $t(item.title) }}</span>
                        </template>
                        <Tooltip v-else :content="$t(item.title)" placement="right">
                            <i :class="item.icon" />
                        </Tooltip>
                    </MenuItem>
                </Menu>
            </div>

            <div class="footer">
                <div class="menu-fold-icon" @click="showMenuTitle = !showMenuTitle">
                    <i v-show="showMenuTitle" class="icon iconfont icon-menu-fold" />
                    <i v-show="!showMenuTitle" class="icon iconfont icon-menu-unfold" />
                    <span v-show="showMenuTitle">
                        {{ $t("x_fold_menu") }}
                    </span>
                </div>
            </div>
        </div>
        <AppContent :style="{ width: showMenuTitle === true ? 'calc(100% - 224px)' : 'calc(100% - 70px)' }">
            <router-view />
        </AppContent>
    </div>
</template>

<style lang="less" scoped>
#leftMenu{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: @gt-bg-color-1;
    .left-menu{
        flex-shrink: 0;
        width: 224px;
        height: 100%;
        border-right: 1px solid @gt-border-color-1;
        display: flex;
        flex-direction: column;
    }
    .left-menu-hide{
        flex-shrink: 0;
        width: 70px;
        height: 100%;
        border-right: 1px @gt-border-color-1 solid;
        display: flex;
        flex-direction: column;
    }
    .ivu-menu{
        width: 100% !important;
    }
    .ivu-menu-light:after{
        background-color: #E8EAEC !important;
        width: 0 !important;
    }
    .ivu-menu-item-selected{
        background-color: @gt-bg-color-2 !important;
    }
    .ivu-menu-item-active:not(.ivu-menu-submenu) {
        border: none !important;
        color: @gt-text-color-5 !important;
    }
    .ivu-menu-item-active:not(.ivu-menu-submenu):after{
        width:0 !important;
        background-color: red !important;
        color: @gt-text-color-5 !important;
    }
}

.footer {
    flex-shrink: 0;
    margin: 0 @gt-page-padding-1 @gt-space-2 @gt-page-padding-1;
    height: 2rem;
    border-top: 1px solid @gt-border-color-1;
    display: flex;
    flex-direction: row;
    align-items: center;

    .menu-fold-icon {
        cursor: pointer;

        &:hover {
            color: @gt-brand-6;
        }
    }
}
</style>
