<script setup>
import { computed, ref } from "vue";
import AuthHeader from "@/pages/auth/AuthHeader.vue";
import store from "@/store/index.js";
import Service from "@/libs/service.js";

const deviceType = computed(() => {
    return store.state.deviceType;
});

const login_bg = ref("/images/login_bg.svg");
// const login_bg = localStorage.getItem("login_bg") || ;

if (window.subdomainName) {
    Service.request(
        "get",
        `/sys/company/logo?website=${window.subdomainName}`,
        {},
        (dt) => {
            login_bg.value = dt.data.loginBackground;
            localStorage.login_title = dt.data.loginTitle;
            localStorage.login_bg = dt.data.loginBackground;
            localStorage.login_logo = dt.data.logo;
        },
        (err) => {
            console.error(err);
        },
    );
}
</script>

<template>
    <div class="auth-wrapper">
        <AuthHeader />
        <div :style="{ backgroundImage: `url(${login_bg})` }" class="auth-content-wrapper">
            <div class="auth-content">
                <div class="introduce">
                    <div class="title">
                        {{ $t("x_digital_twins") }}
                    </div>
                    <div class="content">
                        {{ $t("x_auth_intro") }}
                    </div>
                </div>
                <Card style="background-color: white !important;" class="auth-card">
                    <router-view />
                </Card>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.auth-wrapper {
    width: 100%;
    height: 100%;
    background-color: #F9F9F9;

    display: flex;
    flex-direction: column;
    color: #17233D;
}

.auth-content-wrapper {
    container-name: wrap;
    position: relative;
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;

    .auth-content {
        container-name: auth-content;
        container-type: size;

        position: relative;
        width: 110rem;
        height: 90vh;
        //background-image: url("/images/login_bg.svg");
        //background-repeat: no-repeat;
        //background-position: 0 -160px;
        overflow: hidden;

        .introduce {
            position: absolute;
            left: 8rem;
            top: 5rem;
            width: 25rem;

            .title {
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 1rem;
                color: @gt-brand-6;
            }

            .content {
                font-size: @gt-size-6;
                color: #17233D;
            }
        }

        .auth-card {
            position: absolute;
            top: 60px;
            right: 130px;
            padding: 30px;
            max-width: 500px;
            width: 90%;
            //min-height: 693px;
            max-height: 86vh;
            //margin-right: 220px;
            box-shadow: 0 0 25px 5px rgba(0, 0, 0, 0.1) !important;
            overflow: hidden;

            :deep(.ivu-card-body) {
                height: 100%;

            }
        }
    }
}

@container auth-content (height < 700px) {
    .auth-card {
        top: 20px !important;
    }
}

.text {
    color: @gt-text-color-5;
}

@media screen and (max-width: 500px) {
    .auth-content {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        background-position: -100px -300px !important;

        .auth-card {
            right: unset !important;
            min-height: unset  !important;
        }
    }
}
</style>
