import Cookies from "js-cookie";
import Service from "@/libs/service.js";

const company = {
    namespaced: true,
    state: {
        companyInfo: JSON.parse(localStorage.getItem("companyInfo") || "{}"),
    },
    getters: {},
    mutations: {
        setCompanyInfo(state, companyInfo) {
            localStorage.companyInfo = JSON.stringify(companyInfo);
            Cookies.set("srid", companyInfo.srid);
            state.companyInfo = companyInfo;
        },
    },
    actions: {
        queryCompanyInfo({ commit }, companyId) {
            if (!companyId && companyId !== 0)
                companyId = Cookies.get("companyId");

            return new Promise((resolve, reject) => {
                Service.request(
                    "get",
                    `/sys/company/info/${companyId}`,
                    {},
                    (dt) => {
                        commit("setCompanyInfo", dt.data);
                        resolve(dt.data);
                    },
                    (err) => {
                        reject(err);
                    },
                );
            });
        },
    },
};

export default company;
