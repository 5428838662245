<script setup>
import { onMounted, ref } from "vue";
import { useWebSocket } from "@vueuse/core";
import Cookies from "js-cookie";
import Service from "@/libs/service.js";
import router from "@/router/index.js";

const noticeTotal = ref(0);
const noticesList = ref([]);

const wsProtocol = (window.location.protocol === "https:" || import.meta.env.DEV) ? "wss:" : "ws:";
const sdmsToken = Cookies.get("sdms_token")
    ? Cookies.get("sdms_token")
    : Cookies.get("workspace_token");
const companyId = Cookies.get("companyId");
const userId = Cookies.get("userId");
const socketUrl = `${wsProtocol}//${window.socketSource}/notice/${companyId}/${userId}?token=${sdmsToken}`;
const { ws, close } = useWebSocket(socketUrl, {
    autoReconnect: true,
    heartbeat: {
        message: "heartbeat",
        interval: 10000,
        pongTimeout: 15000,
    },
});

ws.value.addEventListener("message", (event) => {
    // if (event.message === "status update")
    //     noticeIconClick();
    const obj = JSON.parse(event.data);

    if (obj.message === "status update")
        noticeIconClick();
});

onMounted(() => {
    noticeIconClick();
    window.addEventListener("beforeunload", () => {
        close();
    });
});

function jumpToLink({ link, noticeId }) {
    if (link)
        window.open(link, "_blank");

    if (noticeId)
        markNotice(noticeId);
}

function markNotice(noticeId) {
    const query = noticeId
        ? {
                noticeId,
            }
        : undefined;
    Service.request(
        "post",
        "/sys/notice/read",
        query,
        () => {
            noticeIconClick();
        },
        (err) => { console.error(err); },
        {
            skipSuccess: true,
        },
    );
}

function noticeIconClick() {
    Service.request(
        "get",
        "/sys/notice/page?limit=10&page=1&read=false",
        undefined,
        (dt) => {
            noticesList.value = dt?.page?.list ?? [];
            noticeTotal.value = dt?.page?.totalCount;
        },
        (err) => { console.error(err); },
        {
            skipSuccess: true,
        },
    );
}
</script>

<template>
    <Poptip placement="bottom-end" word-wrap trigger="click">
        <Badge :count="noticeTotal">
            <Button type="text" class="bell-button">
                <i class="fal fa-bell" />
            </Button>
        </Badge>
        <template #content>
            <div class="notice-wrapper">
                <div v-if="noticesList.length > 0" class="notice-list">
                    <div v-for="notice in noticesList" :key="notice.noticeId" class="notice-item">
                        <p
                            class="notice-content" :class="{ 'notice-link': notice.link }" :title="notice.content"
                            @click="jumpToLink(notice)"
                        >
                            <i class="notice-icon fal fa-envelope" />
                            <span>{{ notice.content }}</span>
                        </p>
                        <div class="notice-source">
                            <div class="notice-time">
                                {{ notice.updateTime }}
                            </div>
                        </div>
                    </div>
                </div>
                <template v-else>
                    <div class="notice-nodata">
                        {{ $t("x_nodata") }}
                    </div>
                </template>

                <div class="notice-bottom-bar">
                    <div class="notice-bottom-content" @click="markNotice">
                        {{ $t("x_mark_all_messages_as_readed") }}
                    </div>
                    <div class="notice-bottom-content" @click="router.push({ name: 'account-noticesList' })">
                        {{ $t("x_view_all") }}
                    </div>
                </div>
            </div>
        </template>
    </Poptip>
</template>

<style scoped lang="less">
:deep(.ivu-badge) {
    width: 32px;
    height: 32px;
    // background: #eee;
    border-radius: 6px;
    display: inline-block;
}

.bell-button, #upload-poptip-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white
}

.notice-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-wrap: break-word;
    width: 384px;
    height: 299px;

    .notice-list {
        height: calc(~'100% - 38px');
        overflow-y: scroll;

        .notice-item {
            padding: 8px;

            &:hover {
                background: white !important;
            }

            .notice-content {
                word-break: break-all;
                display: -webkit-box !important;
                overflow: hidden;
                -webkit-box-orient: vertical !important;
                -webkit-line-clamp: 2 !important;

                .notice-icon {
                    display: inline;
                    margin-right: 8px;
                }
            }

            .notice-link {
                cursor: pointer;
            }

            .notice-source {
                display: flex;
                flex-direction: row;
                margin-top: 8px;
                color: @gt-text-color-4;

            }
        }
    }

    .notice-bottom-bar {
        position: absolute;
        width: 100%;
        bottom: 8px;
        padding: 8px 12px;
        border-top: 1px solid @gt-border-color-1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .notice-bottom-content {
            cursor: pointer;

            &:hover {
                color: @gt-brand-6;
                text-decoration: underline;
            }
        }
    }
}

.notice-nodata {
    height: calc(~'100% - 38px');
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
