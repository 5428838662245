<script setup>
import useTheme from "@/hooks/useTheme.js";

const { theme, setTheme, THEME } = useTheme();

function toggleTheme() {
    if (theme.value === THEME.LIGHT)
        setTheme(THEME.DARK);
    else
        setTheme(THEME.LIGHT);
}
</script>

<template>
    <Button type="text" class="theme-btn" @click="toggleTheme">
        <i v-if="theme === THEME.DARK" class="text fal fa-moon" />
        <i v-else class="text fal fa-lightbulb-on text" />
    </Button>
</template>

<style scoped lang="less">
.theme-btn {
    color: white !important;
}
</style>
