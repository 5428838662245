const projectDetailRoutes = [
    {
        path: "/project/scene",
        name: "project-scene",
        title: "x_scene",
        meta: {
            title: "x_scene",
        },
        component: () => import("@/pages/projects/scene/index.vue"),
    },
    {
        path: "/project/dashboard",
        name: "project-dashboard",
        title: "x_dashboard",
        meta: {
            title: "x_dashboard",
        },
        component: () => import("@/pages/projects/dashboards/index.vue"),
    },
    {
        path: "/project/resource",
        name: "project-resource",
        title: "x_overview_resourve",
        meta: {
            title: "x_overview_resourve",
        },
        component: () => import("@/pages/projects/resource/index.vue"),
    },
    {
        path: "/project/members",
        name: "project-member",
        title: "x_members",
        meta: {
            title: "x_members",
        },
        component: () => import("@/pages/projects/member/index.vue"),
    },
    {
        path: "/project/general",
        name: "project-setting",
        title: "x_general",
        meta: {
            title: "x_general",
        },
        component: () => import("@/pages/projects/setting/setting.vue"),
    },
    {
        path: "/project/asset",
        name: "project-asset",
        title: "x_assets",
        meta: {
            title: "x_assets",
        },
        component: () => import("@/pages/projectAsset/index.vue"),
    },
    {
        path: "/project/albums",
        name: "project-ai-albums",
        title: "x_pms",
        meta: {
            title: "x_pms",
        },
        component: () => import("@/pages/projects/ai/albums.vue"),
    },
    {
        path: "/project/pms",
        name: "project-ai-pms",
        title: "x_pms",
        meta: {
            title: "x_pms",
        },
        component: () => import("@/pages/projects/ai/pms.vue"),
    },
    {
        path: "/project/tasks",
        name: "project-tasks",
        title: "AI_APP_TASK",
        meta: {
            title: "AI_APP_TASK",
        },
        component: () => import("@/pages/projects/ai/tasks.vue"),
    },
    {
        path: "/project/reports",
        name: "project-reports",
        title: "AI_APP_AI_REPORT",
        meta: {
            title: "x_loadAI_APP_AI_REPORTing",
        },
        component: () => import("@/pages/projects/ai/reports.vue"),
    },
    {
        path: "/project/results",
        name: "project-results",
        title: "AI_APP_AI_RESULT",
        meta: {
            title: "AI_APP_AI_RESULT",
        },
        component: () => import("@/pages/projects/ai/results.vue"),
    },
    {
        path: "/project/odmtasks",
        name: "project-odmtasks",
        title: "x_odm_task",
        meta: {
            title: "x_odm_task",
        },
        component: () => import("@/pages/projects/ai/odmTasks.vue"),
    },
    {
        path: "/project/splattasks",
        name: "project-splattasks",
        title: "x_splat_task",
        meta: {
            title: "x_splat_task",
        },
        component: () => import("@/pages/projects/ai/SplatTask.vue"),
    },
];

export default projectDetailRoutes;
